export const VIDEO_CATEGORIES = [
  "space",
  "underwater",
  "cityscape",
  "landscape",
] as const;

type VideoCategoryTuple = typeof VIDEO_CATEGORIES;
export type VideoCategory = VideoCategoryTuple[number];

export function isVideoCategory(value?: string): value is VideoCategory {
  return (
    value !== undefined && VIDEO_CATEGORIES.includes(value as VideoCategory)
  );
}
