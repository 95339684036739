import { createRouter, createWebHistory } from "vue-router";
import FancyView from "./components/FancyView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      alias: ["/fancy", "/aerial"],
      name: "fancy",
      component: FancyView,
      props: (route) => ({
        mode: route.query.mode,
      }),
      meta: {
        title: "AppleTV but better in every way",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title as string;
  }
  next();
});

export default router;
